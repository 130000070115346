<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Student Credit</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Student-Affairs' }">Student Affairs</router-link>
          </li>
          <li class="breadcrumb-item active">Transactions</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <a href="javascript:void()" data-toggle="modal" data-target="#manageStudentCredit" class="btn btn-primary"
              v-can="'add_student_transactions'" onclick="this.blur();">
              Add New
            </a>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <form id="financial_bank_statement" @submit.prevent="submitSearchForm()">
                  <div class="form-row align-items-end">
                    <div class="form-group col-md-3">
                      <label>Date From <span style="color: red">*</span></label>
                      <input required type="date" class="form-control" name="date_from"
                        v-model="search_fields.date_from" />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Date To <span style="color: red">*</span></label>
                      <input required type="date" class="form-control" name="date_to" v-model="search_fields.date_to" />
                    </div>
                    <div class="form-group col-md-3">
                      <button :disabled="disableBtn" name="submit" type="submit" class="btn btn-primary" value="Submit"
                        onclick="this.blur();">
                        Filter
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>


            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <!-- form will be here -->
                <h3 v-if="student_id">
                  Student Balance: {{ student_balance }}
                </h3>
                <div v-if="dept_totals">
                  <h3 v-for="(row, index) in dept_totals" :key="index">
                    {{ row.category }}: {{ row.amount }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="table-responsive">

              <EasyDataTable :loading="loading" :rows-per-page="10" :rows-items="[25, 50, 100]" :headers="headers"
                :items="transactions" alternating buttons-pagination>



                <template #item-student_code="{ student }">
                  <div>{{ student.user.code }}</div>
                </template>
                <template #item-student_name="{ student }">
                  <div>
                    {{ student.user.firstname }}
                    {{ student.user.middlename }}
                    {{ student.user.lastname }}
                  </div>
                </template>
                <template #item-level="{ student }">
                  <div>{{ student.level.value }}</div>
                </template>
                <template #item-class="{ student }">
                  <div>{{ student.class.name }}</div>
                </template>
                <template #item-year="{ student }">
                  <div>{{ student.year.value }}</div>
                </template>
                <template #item-actions="{ id }">
                  <div>
                    <a v-can="'delete_student_transactions'" @click.prevent="deleteRecord(id)" href="javascript:void()"
                      title="Delete"><i class="fa fa-close color-danger"></i></a>
                  </div>
                </template>
              </EasyDataTable>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Manage Student Credit -->
    <div v-can="'add_student_transactions'" class="modal fade" id="manageStudentCredit" tabindex="-1" role="dialog"
      aria-labelledby="manageStudentCreditLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="manageStudentCreditLabel">
              Manage Student Credit
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <feedback-messages :msgs="msgs" :errors="errors" />
            <form action="" id="manageStudentCreditForm">
              <div v-if="student_id">
                <input type="hidden" name="student_id" v-model="student_id" />
              </div>
              <div v-else class="form-group row">
                <label class="col-md-3 col-form-label">Student <span style="color: red">*</span>
                </label>
                <div class="col-md-9" v-if="lists">
                  <TypeaheadInput v-model="from_student_id" :items="lists.students" itemText="student_name" itemValue="id"
                    placeholder="Choose a student"></TypeaheadInput>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">
                  Amount <span style="color: red">*</span>
                </label>
                <div class="col-md-9">
                  <input type="number" name="amount" class="form-control" placeholder="Amount" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-form-label">
                  Type <span style="color: red">*</span>
                </label>
                <div class="col-md-9">
                  <select class="form-control" name="type" required>
                    <option value="null" disabled selected>
                      Nothing Selected
                    </option>
                    <option value="Add">Add</option>
                    <option value="Deduct">Deduct</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Comment</label>
                <div class="col-md-9">
                  <textarea class="form-control" name="comment"></textarea>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" id="close" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" :disabled="disableBtn" class="btn btn-primary"
              @click.prevent="SubmitManageStudentCreditForm()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeaheadInput from "vue3-typeahead-input";
import "vue3-typeahead-input/dist/style.css"; //Optional default CSS

import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useStudentFinancialTransactions from "@/composables/student_financial_transactions";
import { onMounted, inject, onBeforeMount, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';

export default {
  components: {
    FeedbackMessages,
    TypeaheadInput,
    EasyDataTable
  },
  setup() {
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const route = useRoute();
    const student_id = ref(null);
    const from_student_id = ref(null);
    const loading = ref(true);

    // const form = reactive({
    //   type: null,
    //   amount: 0,
    //   comment: "",
    // });

    const search_fields = reactive({
      student_id: student_id,
      date_from: null,
      date_to: null,
    });

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_student_transactions"))
        router.push({
          name: "401",
        });
    });

    const {
      lists,
      errors,
      transactions,
      student_balance,
      dept_totals,
      getTransactions,
      storeTransaction,
      deleteTransaction,
    } = useStudentFinancialTransactions();

    onMounted(() => {
      if (typeof route.query.student_id !== "undefined") {
        student_id.value = route.query.student_id;
      }
      getTransactions(search_fields).then(() => {
        loading.value = false;
      });
    });

    const deleteRecord = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteTransaction(id).then(() => {
              swal.fire(
                "Deleted!",
                "The data has been deleted successfully.",
                "success"
              );
              getTransactions(search_fields);
            });
          }
        });
    };

    const SubmitManageStudentCreditForm = async () => {
      if (hasPermission("add_student_transactions")) {
        disableBtn.value = true;
        var formDataObj = new FormData();
        const form = document.querySelector("#manageStudentCreditForm");
        Array.from(form.elements).forEach((input) => {
          formDataObj.append(input.name, input.value);
        });

        if (typeof route.query.student_id === "undefined")
          formDataObj.append("student_id", from_student_id.value);

        swal.fire({
          title: "Please wait while submit your request",
          allowOutsideClick: false,
          didOpen: () => {
            swal.showLoading();
          },
        });

        await storeTransaction(formDataObj).then((resp) => {
          swal.close();
          if (resp != "fail") {
            swal.fire({
              icon: "success",
              title: "Your work has been saved",
            });
            //transactions.value.push(resp);
            getTransactions(search_fields);
            disableBtn.value = false;
            document.getElementById("close").click();
          } else {
            console.log("errors", errors);
            swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }

          window.scrollTo(0, 0);
          disableBtn.value = false;
        });
      } else {
        alert("You are not permitted!");
      }
    };

    const submitSearchForm = async () => {
      loading.value = true;
      await getTransactions(search_fields).then(() => {
        loading.value = false;
      });
    };

    const headers = ref([
      { text: "Code", value: "student_code" },
      { text: "Name", value: "student_name", sortable: true },
      { text: "Level", value: "level", sortable: true },
      { text: "Class", value: "class", sortable: true },
      { text: "Year", value: "year", sortable: true },
      { text: "Charged By", value: "charged_by_name", sortable: true },
      { text: "Type", value: "type", sortable: true },
      { text: "Category", value: "spent_at", sortable: true },
      { text: "Amount", value: "amount", sortable: true },
      { text: "Date", value: "created_at", sortable: true },
      { text: "Comment", value: "comment", sortable: true },
      { text: "Actions", value: "actions", sortable: true },
    ]);

    return {
      headers,
      loading,
      from_student_id,
      errors,
      lists,
      student_id,
      student_balance,
      dept_totals,
      disableBtn,
      transactions,
      search_fields,
      deleteRecord,
      submitSearchForm,
      SubmitManageStudentCreditForm,
    };
  },
};
</script>


<style>
.vue3-easy-data-table__header tr th .header {
  position: unset !important;
  color: black !important;
  text-align: left !important;
  background-color: #fafafa !important;
  padding-left: 1px !important;
  height: 2rem !important;
}
</style>
